const getStoresforConsumer = (request) => {
    return new Promise((res, rej) => {
        axios.defaults.headers.common["Authorization"] = localStorage.getItem("consumer_token");
        axios.post('getstoresforconsumer', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const makefav = (request) => {
    return new Promise((res, rej) => {
        axios.defaults.headers.common["Authorization"] = localStorage.getItem("consumer_token");
        axios.post('markunmarkfavouritestore', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const getStoreDetails = (request) => {
    return new Promise((res, rej) => {
        axios.defaults.headers.common["Authorization"] = localStorage.getItem("consumer_token");
        axios.post('stores/details', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const getstorelistingforConsumer = (request) => {
    return new Promise((res, rej) => {
        axios.defaults.headers.common["Authorization"] = localStorage.getItem("consumer_token");
        axios.post('maps/getstorelistingforconsumer', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

export default {
    getStoresforConsumer,
    makefav,
    getStoreDetails,
    getstorelistingforConsumer
};